import React from 'react'
import "./section6.css"
import bigs7 from "../../images/bigs7.png"
import bigs72 from "../../images/bigs72.png"
import bigs73 from "../../images/bigs73.png"
import bigs74 from "../../images/bigs74.png"
import bigs75 from "../../images/bigs75.png"
import bigs76 from "../../images/bigs76.png"
import bigs77 from "../../images/bigs77.png"
import bigs78 from "../../images/bigs78.png"

const data = [
    {
     img: bigs7 , 
     title:"BigCommerce Website Design" , 
     para:"Send your design mock-ups in any format (PSD, Ai, Sketch, Adobe XD, etc.) and we will convert them into a fully functional and responsive BigCommerce website."
    } , 
   
    {
     img: bigs72 , 
     title:"Converting Your Site to BigCommerce" , 
     para:"We take your existing site and convert it to BigCommerce website. With ease."
    } , 
    {
     img: bigs73 , 
     title:"Tweaks to Existing BigCommerce Websites" , 
     para:"Do you need a feature added to an existing site or a new page? We can take care of that."
    } , 
   
    {
     img: bigs74 , 
     title:"Multilingual Support" , 
     para:"Your site is available in different languages? No problem, we can do this."
    } , 
    {
     img: bigs75 , 
     title:"Theme Options Implementation" , 
     para:"All pages can be easily edited through the Store Customize admin area."
    } , 
   
    {
     img: bigs76 , 
     title:"Custom Modules Integration" , 
     para:"We can boost your new or existing BigCommerce website with additional features."
    } , 
    {
     img: bigs77 , 
     title:"Mega-Level Dropdown" , 
     para:"A flawlessly working navigation, functioning smoothly just like it's supposed to."
    } , 
   
    {
     img: bigs78 , 
     title:"Browsers Support" , 
     para:"Our projects look great on all modern web browsers and devices. We rarely use simulators - we test our projects on real browsers and devices."
    } , 
   
   
   
]

function BigSection7() {
  return (
    <div className='bigsec6wrap addbgs7'>

     <div className="bigsec6cont">

        <div className="bigs6topcon">
            <h3>BigCommerce Development Features</h3>
            <p>Quality simply matters. Here are all the extras you get with BigCommerce development.</p>
        </div>


        <div className="alls6elems">
            {
                data.map((item , index)=>(
                    <div key={index} className="singeles7">

                        <img src={item.img} alt="" />

                        <h5>{item.title}</h5>

                        <p>{item.para}</p>

                    </div>
                ))
            }
        </div>


     </div>

    </div>
  )
}

export default BigSection7