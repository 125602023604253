import React, { useState, useEffect } from 'react';
import './section7.css';
// import nodejs from '../../images/nodejsimg.webp';
// import angular from '../../images/angulars8.webp';
// import react from '../../images/reacts8.webp';
// import vue from '../../images/vues8.webp';
// import larvel from '../../images/larvels8.webp';
// import php from '../../images/phps8.webp';
// import html from '../../images/htmls8.webp';
// import wordpress from '../../images/words7.webp';
// import bigcommerce1 from "../../images/bigcommerce1.webp";
// import shopify1 from "../../images/shopify1.webp";
// import flutter1 from "../../images/fultter1.webp";
// import javascript1 from "../../images/javascript1.webp";
// import mongodb1 from "../../images/mongodb1.webp";
// import magento1 from "../../images/magento1.webp";
// import kotlin1 from "../../images/kotlin1.webp";
// import woocommerce1 from "../../images/woocomme1.webp";
import { NavLink } from 'react-router-dom';

function Home2Section5() {
  const [isFirstSet, setIsFirstSet] = useState(true);
  const [showImages, setShowImages] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowImages(false);
      setTimeout(() => {
        setIsFirstSet((prev) => !prev);
        setShowImages(true);
      }, 500);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="s5wrap">

      <div className='s5maincont'>



        <div className="s5cont">

          <div className="s5content">
            <h3>
              <span>We Build</span> with the Best to Ensure Your Success
            </h3>
            <p>
              Our expert minds at Kushel Digi leverage top-tier technologies to bring your vision to life with precision and reliability. We work on Node, Angular, React, Laravel, MongoDB, HTML5, PHP, ASP.NET, and WordPress. For the best user interface, we use CSS3, Magento, CMS, and object modeling.
            </p>
          </div>
         <NavLink to="/contact-us"><button>
            <span>Let's connect</span>
          </button></NavLink>
        </div>

        <div className={`image-container ${showImages ? "fade-in" : "fade-out"}`}>
          {isFirstSet ? (
            <>
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767136/Group_1000011223_in1dh6.png" className="nodejs1 displaynone sameimgs5" alt="NodeJS" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767140/Group_1000011229_1_ayymso.png
" className="angular1 displaynone sameimgs5" alt="Angular" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767137/Group_1000011225_cqba1f.png
" className="react1 displaynone sameimgs5" alt="React" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724766977/Group_1000011216_p62mua.png
" className="vue1 displaynone sameimgs5" alt="Vue" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767065/Group_1000011219_1_bmakmn.png
" className="larvel1 displaynone sameimgs5" alt="Laravel" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724766976/Group_112_ed3s7q.png
" className="php1 displaynone sameimgs5" alt="PHP" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767138/Group_1000011227_1_prwr0a.png
" className="html1 displaynone sameimgs5" alt="HTML" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724766980/Group_1000011218_1_thqeyc.png
" className="wordpress1 displaynone sameimgs5" alt="WordPress" />
            </>
          ) : (
            <>
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767152/Group_1171275943_yq6yrg.png
" className="nodejs1 displaynone sameimgs5" alt="MongoDB" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767152/Group_1171275926_oyvwef.png
" className="react1 displaynone sameimgs5" alt="BigCommerce" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767139/Group_1000011227_taehf3.png
" className="html1 displaynone sameimgs5" alt="JavaScript" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767141/Group_1000011229_globko.png
" className="angular1 displaynone sameimgs5" alt="Flutter" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724769827/Group_1000011225_nqhcvk.png
" className="vue1 displaynone sameimgs5" alt="Magento" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767154/Group_1171275946_lefslc.png
" className="php1 displaynone sameimgs5" alt="Shopify" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724766980/Group_1000011218_f4cxsf.png
" className="wordpress1 displaynone sameimgs5" alt="WooCommerce" />
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724767136/Group_1000011219_ldbpnn.png
" className="larvel1 displaynone sameimgs5" alt="Kotlin" />
            </>
          )}
        </div>

      </div>

    </div>
  );
}

export default Home2Section5;
