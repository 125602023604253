import React from 'react'
import "./section6.css"
import settingImg from "../../images/setingimg.png"
import settingImg2 from "../../images/settingImg2.png"
import settingImg3 from "../../images/settingImg3.png"
import settingImg4 from "../../images/settingImg4.png"
import settingImg5 from "../../images/settingImg5.png"
import settingImg6 from "../../images/settingImg6.png"

const data = [
    {
     img: settingImg , 
     title:"Managers Who Know BigCommerce Development" , 
     para:"All our managers have rich coding experience, so they know everything specific about BigCommerce development."
    } , 
    {
     img: settingImg2 , 
     title:"Smooth Workflow" , 
     para:"We know the ins and outs of BigCommerce development. As a result, we've developed an efficient and smooth workflow to convert your BigCommerce website design."
    } , 
    {
     img: settingImg3 , 
     title:"Support" , 
     para:"Reliability is of utmost importance to us. If any bugs emerge, even well after the BigCommerce development ended, just get in touch."
    } , 
    {
     img: settingImg4 , 
     title:"We Respect Confidentiality (NDA)" , 
     para:"We'd never claim any rights on your BigCommerce website designs. We will willingly sign an NDA prior to the BigCommerce development starts."
    } , 
    {
     img: settingImg5 , 
     title:"Content Population" , 
     para:"You need content populated to your fresh BigCommerce website? Just buzz us and we'd be glad to help you with that."
    } , 
    {
     img: settingImg6 , 
     title:"Dedicated BigCommerce development team" , 
     para:"A team that knows your BigCommerce development needs, provides daily support and helps you with new modifications and functionalities."
    } , 
]

function Bigsection6() {
  return (
    <div className='bigsec6wrap'>

     <div className="bigsec6cont">


        <div className="bigs6topcon">
            <h3>Why BigCommerce Development by Kushel Digi Solutions?</h3>
            <p>Because your BigCommerce website design deserves the best care. Check out just a few benefits our BigCommerce development services will bring to you.</p>
        </div>

        <div className="alls6elems2">
            {
                data.map((item , index)=>(
                    <div key={index} className="singeles6">

                        <img src={item.img} alt="" />

                        <h5>{item.title}</h5>

                        <p>{item.para}</p>

                    </div>
                ))
            }
        </div>



     </div>

    </div>
  )
}

export default Bigsection6