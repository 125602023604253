import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './styles.css';
import brandswipe from '../../images/brandsiwp1.webp';
import brandswipe2 from '../../images/brandsiwp2.webp';
import brandswipe3 from '../../images/brandsiwp3.webp';
import brandswipe4 from '../../images/brandsiwp4.webp';
import brandswipe5 from '../../images/brandsiwp5.webp';
import brandswipe6 from '../../images/brandsiwp6.webp';
import brandswipe7 from '../../images/brandsiwp7.webp';
import brandswipe8 from '../../images/brandsiwp8.webp';
import brandswipe9 from '../../images/brandsiwp9.webp';
import brandswipe10 from '../../images/brandsiwp10.webp';
import brandswipe11 from '../../images/brandsiwp11.webp';
import hl1 from '../../images/hl1.png'
import hl2 from '../../images/hl2.png'
import hl3 from '../../images/hl3.png'
import hl4 from '../../images/hl4.png'
import { NavLink } from 'react-router-dom';

function Home2BrandSwiper({title}) {
  return (
    <div className='h2braswiperwrap'>

      <h3>{title}</h3>

      <div className='brandswicont'>

  
      <Splide
          options={
            {
            type: 'loop',
            perPage: 5,
            focus: 'center',
            gap: '20px',
            start:0 , 
            interval: 0,
            speed:100000 , 
            autoplay: true,
            interval: 1500,
            pagination: false,
            arrows: false, 
            breakpoints: {
              550: {
                perPage: 2,
                gap: '10px',
              },
              1024: {
                perPage: 4,
                gap: '15px',
              },

              1400: {
                perPage: 5,
                gap: '20px',
              },
            },
  
          }}
        className='branchswipe2'
      >

         <SplideSlide>
          <div>
            <img src={hl1} className='brandswipe' alt="Brand 1" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div>
            <img src={hl2} className='brandswipe' alt="Brand 1" />
          </div>
        </SplideSlide>
        {/* <SplideSlide>
          <div>
            <img src={hl3} className='brandswipe' alt="Brand 1" />
          </div>
        </SplideSlide> */}
        <SplideSlide>
          <div>
            <img src={hl4} className='brandswipe' alt="Brand 1" />
          </div>
        </SplideSlide>

        {/* ======= */}

        <SplideSlide>
          <div>
            <img src={brandswipe} className='brandswipe' alt="Brand 1" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe2} className='brandswipe' alt="Brand 2" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe3} className='brandswipe' alt="Brand 3" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe4} className='brandswipe' alt="Brand 4" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe5} className='brandswipe' alt="Brand 5" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe6} className='brandswipe' alt="Brand 6" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe7} className='brandswipe' alt="Brand 7" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe8} className='brandswipe' alt="Brand 8" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe9} className='brandswipe' alt="Brand 9" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe10} className='brandswipe' alt="Brand 10" />
          </div>
        </SplideSlide>

        <SplideSlide>
          <div>
            <img src={brandswipe11} className='brandswipe' alt="Brand 11" />
          </div>
        </SplideSlide>

      </Splide>

      </div>

      <NavLink to={"/portfolio"}>
        <button className='viewworkbtns'>
          <span>VIEW OUR WORK</span>
        </button>
      </NavLink>

    </div>
  );
}

export default Home2BrandSwiper;
