import React, { useState, useRef } from "react";
import whykushle from "../images/whyKushle.png";
import ContactForm1 from "./common/ContactForm1";
import londa from "../images/londa.png";
import arrow3 from "../images/arrow3.png";

import dust from "../images/dust.png";
import ent1 from "../images/ent1.png";
import ent2 from "../images/ent2.png";
import ent3 from "../images/ent3.png";
import ent4 from "../images/ent4.png";
import ent5 from "../images/ent5.png";
import ent6 from "../images/ent6.png";
import ent7 from "../images/ent7.png";
import ent8 from "../images/ent8.png";
import ent9 from "../images/ent9.png";
import ent10 from "../images/ent10.png";
import "../components/css/app3.css";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import whatsApp from "../images/whatsapp.png";
import call from "../images/telephone.png"
import ContactForm from "./common/ContactForm";
import { Helmet } from "react-helmet";

const OurPartner = ({notify}) => {
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [user1, setUser1] = useState({
    phone:""
  })

  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const ref4 = useRef(null);

  const ref6 = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    document.getElementById("bff").classList.remove("paking");
    document.getElementById("bff1").classList.add("paking");
    document.getElementById("bff2").classList.remove("paking");
    document.getElementById("bff4").classList.remove("paking");
    document.getElementById("bff6").classList.remove("paking");
    document.getElementById("offer5").style.paddingTop = "8.8rem";
  };

  const handleClick1 = () => {
    ref1.current?.scrollIntoView({ behavior: "smooth" });
    document.getElementById("bff").classList.add("paking");
    document.getElementById("bff1").classList.remove("paking");
    document.getElementById("bff2").classList.remove("paking");
    document.getElementById("bff4").classList.remove("paking");
    document.getElementById("bff6").classList.remove("paking");
    document.getElementById("offer5").style.paddingTop = "50px";
  };
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({ behavior: "smooth" });
    document.getElementById("bff").classList.remove("paking");
    document.getElementById("bff1").classList.remove("paking");
    document.getElementById("bff2").classList.add("paking");
    document.getElementById("bff4").classList.remove("paking");
    document.getElementById("bff6").classList.remove("paking");
    document.getElementById("offer6").style.paddingTop = "8.8rem";
  };

 

  const handleClick4 = () => {
    ref4.current?.scrollIntoView({ behavior: "smooth" });
    document.getElementById("bff").classList.remove("paking");
    document.getElementById("bff1").classList.remove("paking");
    document.getElementById("bff2").classList.remove("paking");
    document.getElementById("bff4").classList.add("paking");
    document.getElementById("bff6").classList.remove("paking");
    document.getElementById("howIt").style.paddingTop = "8.8rem";
  };

 

  const handleClick6 = () => {
    ref6.current?.scrollIntoView({ behavior: "smooth" });
    document.getElementById("bff").classList.remove("paking");
    document.getElementById("bff1").classList.remove("paking");
    document.getElementById("bff2").classList.remove("paking");
    document.getElementById("bff4").classList.remove("paking");
    document.getElementById("bff6").classList.add("paking");
    document.getElementById("off").style.paddingTop = "8.8rem";
  };

 
  const phoneNumber = "9045301702";

  const whatAppHandler = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const callHandler=()=>{
    const callUrl = `tel:${phoneNumber}`;
    window.open(callUrl, "_blank");
  }


  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const funt1 = () =>{
    if(user1.phone.length> 9){
      document.getElementById("funts").disabled = true
    }
    else{
      document.getElementById("funts").disabled = false
    }

    document.getElementById("funts").disabled = false
  }

  return (
    <>
    <Helmet>
    <title>Your Trusted Ecommerce Web Development Research Partner - TradeImeX</title>
     <meta
         name="description"
         content="Kushel Digi is among the most technologically advanced IT corporations and also runs one of the most profitable and advanced affiliate programs in the IT Industry."
     /> 
    <link rel="canonical" href="https://www.kusheldigi.com/partner"/>
    </Helmet>
      <div className="ser-main">
        <div className="partnerrr-mainn">
        <div className="partner-back dine-1234">
          <div className="partner-sect partnerrr">
            <h4>JOIN US</h4>
            <h1>
              EARN MONEY WORKING FROM <br /> HOME OR ANYWHERE
            </h1>
            <p>
              Our Partnership Program give you the opportunity to build your own
              business.
            </p>
          </div>
          </div>
        </div>
        <div className="kushel-menu">
          <div className="flex items-center justify-around kushel-menu1 dine-123">
            <p id="bff" className=" cursor-pointer" onClick={handleClick1}>
              Why Kushel digi
            </p>
            <p id="bff1" className=" cursor-pointer" onClick={handleClick}>
              our offerings
            </p>
            <p id="bff2" className=" cursor-pointer" onClick={handleClick2}>
              Opportunities
            </p>
            {/* <p id="bff3" className=" cursor-pointer" onClick={handleClick3}>
              Partner Stories
            </p> */}
            <p id="bff4" className=" cursor-pointer" onClick={handleClick4}>
              How it works
            </p>
            {/* <p id="bff5" className=" cursor-pointer" onClick={handleClick5}>
              FAQ
            </p> */}
            <p id="bff6" className=" cursor-pointer" onClick={handleClick6}>
              Our offices
            </p>
          </div>
          <hr className="hr-kushel" />
        </div>
        <div ref={ref1} className="kushel-why dine-123">
          <div className="first-why">
            <h3>Why kushel digi?</h3>
            <p>
              ‘Kushel digi’ is among the most technologically advanced IT
              corporations and also runs one of the most profitable and advanced
              affiliate programs in the IT Industry. The sky’s the limit with
              proper utilization of resources and your skills. We equip you with
              all the resources necessary to make hefty amounts of income
              surpassing your imaginations. A combination of sales support,
              training, and innovative ideas backed by a strong operations team
              await your decision to partner with Us.
            </p>
          </div>
          <div className="second-why">
            <img src={whykushle} alt="why KDS" title="why KDS" />
          </div>
        </div>

        <div className="lattestt">
        <div className="latest dine-1234">
          <div className="business-card flex-wrap">
            <div className="business-box business-box1 partnerr-busii business-box012 relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src={dust} alt="Diversified IT Services" title="Diversified IT Services"/>
              </div>
              <h5>Diversified IT Services</h5>
              <p>
                While associating with Kushel digi you will get the bucket of
                portfolio to offer to your clients. This service portfolio is
                required by most of the companies in their day to day business
                operations.
              </p>
            </div>
            <div className="business-box business-box1 partnerr-busii business-box012 relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer2.png" alt="Latest IT News & Updates" title="Latest IT News & Updates"/>
              </div>
              <h5>Latest IT News & Updates</h5>
              <p>
                As our business partner we will make sure that you remain
                updated with the latest trend and technology news in the market.
                This will help you to acquire more knowledge compared to others
                and help you in boost your sales conversation as well.
              </p>
            </div>
            <div className="business-box business-box1 business-box012 partnerr-busii relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer3.png" alt="Pre-Sales Training & Support" title="Pre-Sales Training & Support" />
              </div>
              <h5>Pre-Sales Training & Support</h5>
              <p>
                Any effective lead generation & conversion requires proper
                training on each offering and handholding during the deal with
                clients. Kushel digi makes sure that it always remains present
                and educates all business partners at regular intervals.
              </p>
            </div>
            <div className="business-box business-box1 business-box012 partnerr-busii relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer4.png" alt="Instant Rewards" title="Instant Rewards" />
              </div>
              <h5>Instant Rewards</h5>
              <p>
                We intend to keep transparency in our working, that is the
                reason we have our own technology developed for a real time
                payout checking system, which helps our business partners to
                know their accounts any moment of time.
              </p>
            </div>
            <div className="business-box business-box1 business-box012 partnerr-busii relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer5.png" alt="Sales Tools" title="Sales Tools" />
              </div>
              <h5>Sales Tools</h5>
              <p>
                At Kushel digi as a business partner we will enable sales tools
                to help you to grow your business and acquire new clients from
                the markets. In this digital era where everything is eventually
                going online we want our business partner to get the best guns
                to fight the battle.
              </p>
            </div>
            <div className="business-box business-box1 business-box012 partnerr-busii relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer6.png" alt="Personalised Marketing Materials" title="Personalised Marketing Materials" />
              </div>
              <h5>Personalised Marketing Materials</h5>
              <p>
                In this competition world where so much content is following
                around it is very important for any partner to push and recall
                their brand. We make sure we provide the personalised connect
                digital marketing material for you to connect to your clients in
                a more effective way.
              </p>
            </div>
            <div className="business-box business-box1 business-box012 partnerr-busii relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer7.png" alt="After Sales Support & Services" title="After Sales Support & Services" />
              </div>
              <h5>After Sales Support & Services</h5>
              <p>
                Just sale is not important but maintaining the long term
                relationship with the client will help you to gain more business
                from your existing clients. VNJ make sure that your clients get
                the best after sales service and fulfilment of the commitment
                which is done during the closure of the deal.
              </p>
            </div>
            <div className="business-box business-box1 business-box012 partnerr-busii relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer8.png" alt="Brand Leverage" title="Brand Leverage" />
              </div>
              <h5>Brand Leverage</h5>
              <p>
                As you are associated with VNJ brand, you have leverage with
                expertise and experience which brand carry over the years. The
                trust and presence which is build over the period of so many
                years will give your additional advantage to close the deal with
                your clients.
              </p>
            </div>
            <div className="business-box business-box1 business-box012 partnerr-busii relative text-center cursor-pointer">
              <div className="business-card-img">
                <img src="/static/images/lawyer9.png" alt="Dedicated Support Desk" title="Dedicated Support Desk" />
              </div>
              <h5>Dedicated Support Desk</h5>
              <p>
                ou as our business partner will never feel alone in the journey.
                Our dedicated business partner relationship manager will be
                always with you in your entire journey.
              </p>
            </div>
          </div>
        </div>
        </div>
        <div id="offer5" ref={ref} className="offering dine-1234">
          <h3>Our Offerings</h3>
          <div className="offer-section">
            <div className="flex offery items-center justify-center">
              <div className="text-center jis">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933723/download_45_exjjaa.png" alt="EnterPrise Software" title="EnterPrise Software" />
                <p className="text-center">
                  EnterPrise Software <br /> Development
                </p>
              </div>
              <div className="text-center jis">
                <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933723/download_46_kkbln3.png" alt=" Mobile App" title=" Mobile App" />
                <p className="text-center">
                  Mobile App <br /> Development
                </p>
              </div>
              <div className="text-center jis">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933722/download_47_j1tye5.png" alt=" Website development " title=" Website development" />
                <p className="text-center">
                  Website <br /> Development
                </p>
              </div>
              <div className="text-center jis">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933721/download_48_loakds.png
" alt="  Data Analytics" title="Data Analytics" />
                <p className="text-center">
                  Data <br />
                  Analytics
                </p>
              </div>
              <div className="text-center jis">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933719/download_49_f5bpci.png" alt="IT Consultancy" title="IT Consultancy" />
                <p className="text-center">
                  IT <br /> Consultancy
                </p>
              </div>
            </div>
            <div className="flex items-center offery justify-center">
              <div className="text-center jis jis1">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933719/download_50_ir97f9.png" alt="Graphic Design" title="Graphic Design" />
                <p className="text-center">Graphic Design</p>
              </div>
              <div className="text-center jis jis1">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933718/download_51_avy7kj.png" alt="Digital Marketing" title="Digital Marketing" />
                <p className="text-center">Digital Marketing</p>
              </div>
              <div className="text-center jis jis1">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933718/download_52_njqfhy.png" alt="UI/UX Designing" title="UI/UX Designing" />
                <p className="text-center">UI/UX Designing</p>
              </div>
              <div className="text-center jis jis1">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933718/download_53_xg5zht.png" alt="Video Marketing" title="Video Marketing" />
                <p className="text-center">Video Marketing</p>
              </div>
              <div className="text-center jis jis1">
                <img className="offer-img" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724933718/download_54_w3itvm.png" alt="Branding Positioning Services" title="Branding Positioning Services" />
                <p className="text-center">
                  Branding Positioning <br /> Services
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="offer6" ref={ref2} className="partnership">
          <div className="first-partnership">
            <h3>
              Kushel Digi <br /> Partnership <br /> Opportunities
            </h3>
          </div>
          <div className="second-partnership w-full ">
            <Splide
              className="w-full tying"
              aria-label="Our Services"
              options={{
                perPage: 1,
                perMove: 1,
                autoplay: true,
                pauseOnHover: true,
                type: "loop",
                interval: 2200,
                drag: true,
              }}
            >
              <SplideSlide>
                <div className="card-part flex items-start justify-center">
                  <div
                    className="box-part"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    <h4>Solution Partners</h4>
                    <p>
                      The greatest e-commerce partners in India work together
                      with Kushel Digi. The delivery of e-commerce solutions is
                      a speciality of our Solution Partners. We create
                      future-oriented solutions together.
                    </p>
                  </div>
                  <div
                    className="box-part box-part1"
                    onMouseEnter={() => setHovered2(true)}
                    onMouseLeave={() => setHovered2(false)}
                  >
                    <h4>Distributors</h4>
                    <p>
                      Improve Business Automation with Our adaptable platform
                      designed with distributors in mind, and it can be used to
                      meet supplier and supply chain requirements. We provide
                      our customers with 24/7.
                    </p>
                  
                  </div>
                  <div
                    className="box-part box-part1"
                    onMouseEnter={() => setHovered3(true)}
                    onMouseLeave={() => setHovered3(false)}
                  >
                    <h4>Master Agents</h4>
                    <p>
                      We have master agents who can reach a wider audience and
                      interact with more customers to help businesses scale more
                      quickly. Tools that people can utilise to carry out their
                      operations in .
                    </p>
                  
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      
        <div ref={ref4} id="howIt" className="asit dine-1234">
          <h2 className="heading">HOW IT WORKS</h2>
          <div className="contant-1">
            <p className="pra1">
              Join the programme by clicking on the ‘Become a Partner’ button
            </p>
            <p className="pra2">
              Receive extensive resources - from marketing &amp; product
              collaterals to training and certification.
            </p>
            <p className="pra3">
              Enjoy incremental rewards as you advance through different partner
              levels.
            </p>
          </div>
          <hr className="line" />
          <div className="contant-2">
            <div
              className="icon-1"
            />
            <div
              className="icon-2"
            />
            <div
              className="icon-3"
            />
            <div
              className="icon-4"
            />
            <div
              className="icon-5"
            />
          </div>
          <div className="contant">
            <div className="contant-3">
              <p className="pra-2">
                Hit the ground running with the help of our structured
                onboarding and orientation process.
              </p>
              <p className="pra-2">
                Co-create value leveraging our ongoing engagement and support
                framework.
              </p>
            </div>
          </div>
        </div>
      
        <div id="off" ref={ref6} className="offices dine-1234">
          <h3>Our Offices</h3>
          <div className="mt-2 picta">
            <p>
              Kushel digi operates in multiple offices and development centers
              across the globe.
            </p>
            <p>
              Reach out to us for software development services in your region.
            </p>
          </div>
          <div className="office-img flex items-center justify-center">
            <div className="office-image">
              <div className="office-image-sect">
                <h4>INDIA</h4>
                <div className="flex items-center">
                  <img width={25} src={londa} alt="location" title="location" />
                  <p className="ml-3">G-9, first Floor, Sector 63, Noida</p>
                </div>
              </div>
            </div>
            <div className="office-image1">
              <div className="office-image-sect">
                <h4>INDIA</h4>
                <div className="flex items-center">
                  <img width={25} src={londa} alt="location"  title="location"/>
                  <p className="ml-3">G-9, first Floor, Sector 63, Noida</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <ContactForm1 notify={notify} />
        {/* <ContactForm notify={notify}/> */}
        <div className="whtsApBtns">
          <button onClick={whatAppHandler}>
            <img className="what-image-universal" src={whatsApp} alt="whatsApp-kusheldigi" title="whatsApp-kusheldigi" />
          </button>
          <button onClick={callHandler}>
            <img src={call} alt="call-icon" title="call-icon" />
          </button>

        </div>
      </div>
    </>
  );
};

export default OurPartner;
