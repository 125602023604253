import React from 'react'
import "./header.css"

function BigHeader() {
  return (
    <div className="bighewrap">

        <div className="bigheacont">

          {/* left side */}
          <div className="bigheleft">
            <h2>We design, develop, Re-platform and optimize custom BigCommerce websites for B2C/B2B brands.</h2>
            <p>We grow and scale mid-market and enterprise businesses by building high-performing custom BigCommerce websites that increase conversions, drive site traffic, decrease bounce rates and maximize average order value (AOV). - Realize your true growth potential and partner with DigitlHaus on your next project!</p>

            <button><span>WORK WITH US</span></button>
          </div>

        {/* right side  */}
         <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1725534480/Frame_1597881899_lyhypk.png" className='bigcomright' alt="" />

            
        </div>

    </div>
  )
}

export default BigHeader