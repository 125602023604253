import "./header.css"

const data = [
    {
        num:"01" , 
        title:"BigCommerce Web Development" , 
        para:"As an award-winning BigCommerce agency, we excel in strategically designing custom, high-performing BigCommerce shopping experiences. Our data-driven approach ensures brand awareness, connects and engages with the customer, drives traffic, eliminates bounce rates, boosts conversions and increases AOV. Our solutions will position your store to outperform your competition and grow and scale your business."
    } ,
    {
        num:"02" , 
        title:"BigCommerce Custom Theme Customization & Development" , 
        para:"From developing completely custom BigCommerce stores to features and functionality, we have developed some of the largest and most complex solutions utilizing the BigCommerce platform. Our development practices are agile, fast, lightweight and responsive. We ensure pixel-perfect quality and a seamless hand-off. Our in-house team is trained, certified and has built over 150 custom stores on the BigCommerce platform."
    } ,
    {
        num:"03" , 
        title:"BigCommerce Migration" , 
        para:"Our BigCommerce migration service includes audits, discovery and planning, to full catalog migration, post deployment redirects and SEO. When re-platforming to BigCommerce our data migration team can migrate 1:1 products, categories, customers, orders, webpages, blog posts, reviews as well as any other data that you require. We will ensure that your migration process is quick, pain-free as well as cost-effective."
    } ,
    {
        num:"04" , 
        title:"BigCommerce Plugin/Extension Development" , 
        para:"Our BigCommerce development team can connect and integrate any CRM or ERP backend system with BigCommerce. We custom develop intricate connectors utilizing BigCommerce’s powerful API, or if available, utilize pre-built connectors provided by our integration partners. Whatever your requirement, we will ensure a seamless BigCommerce integration that is synched and connected the way it should be."
    } ,
    {
        num:"05" , 
        title:"BigCommerce API Integration" , 
        para:"Are you looking to build a custom application unique to your business that connects with your BigCommerce store? We will work collaboratively with you and your team to define the features, flow and requirements for your application and custom design, develop and integrate a solution that meets your expectations and connects perfectly with your backend."
    } ,
    {
        num:"06" , 
        title:"BigCommerce Store Migration" , 
        para:"If your BigCommerce store is no longer capable of providing lightening fast speeds, delivering high-end customer experiences or the ability to easily manage large libraries of content, a BigCommerce headless solution may be for you. We plan, strategize, design, build, launch and optimize headless experiences for merchants ready to future-proof their eCommerce business. "
    } ,
    {
        num:"07" , 
        title:"Payment Gateway Integration and Support" , 
        para:"As an award-winning BigCommerce agency, we excel in strategically designing custom, high-performing BigCommerce shopping experiences. Our data-driven approach ensures brand awareness, connects and engages with the customer, drives traffic, eliminates bounce rates, boosts conversions and increases AOV. Our solutions will position your store to outperform your competition and grow and scale your business."
    } ,
    {
        num:"08" , 
        title:"BigCommerce Consulting" , 
        para:"As an award-winning BigCommerce agency, we excel in strategically designing custom, high-performing BigCommerce shopping experiences. Our data-driven approach ensures brand awareness, connects and engages with the customer, drives traffic, eliminates bounce rates, boosts conversions and increases AOV. Our solutions will position your store to outperform your competition and grow and scale your business."
    } ,
    {
        num:"09" , 
        title:"BigCommerce SEO & Marketing" , 
        para:"As an award-winning BigCommerce agency, we excel in strategically designing custom, high-performing BigCommerce shopping experiences. Our data-driven approach ensures brand awareness, connects and engages with the customer, drives traffic, eliminates bounce rates, boosts conversions and increases AOV. Our solutions will position your store to outperform your competition and grow and scale your business."
    } ,
]

function BigSection3() {
  return (
    <div className='bigcomsec3wrap'>

        <div className="bigcosec3cont">

            <h2>Our BigCommerce <span>Web Development</span> Services</h2>

               <div className="bigwebwrapall">
                 {
           data.map((item , index)=>(
            <div key={index} className="singsec3boxe">

                 <span>{item.num}</span>

                 <h5>{item.title}</h5>

                 <p>{item.para}</p>


            </div>
           ))
                 }
               </div>

        </div>

    </div>
  )
}

export default BigSection3