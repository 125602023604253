import React, { useState } from "react";

import toning1 from "../images/toning1.png";
import toning2 from "../images/toning2.png";
import toning3 from "../images/toning3.png";
import toning4 from "../images/toning4.png";
import laras1 from '../images/laras1.png';
import laras2 from '../images/laras2.png';
import laras3 from '../images/laras3.png';
import laras4 from '../images/laras4.png';
import ikk1 from '../images/icon-1.png';
import ikk2 from '../images/icon-2.png';
import ikk3 from '../images/icon-3.png';
import ikk4 from '../images/icon-4.png';
import ikk5 from '../images/icon-5.png';
import ikk6 from '../images/icon-6.png';
import ikk7 from '../images/icon-7.png';
import ikk8 from '../images/icon-8.png';
import ikk9 from '../images/icon-9.png';
import ikk10 from '../images/icon-10.png';
import ikk11 from '../images/icon-11.png';
import ikk12 from '../images/icon-12.png';
import whatsApp from "../images/whatsapp.png";
import call from "../images/telephone.png"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import soin2 from "../images/2ax.png";
import soin3 from "../images/3ax.png";
import soin4 from "../images/4ax.png";
import soin5 from "../images/5ax.png";
import soin6 from "../images/6ax.png";
import setui from "../images/setui.png";
import ContactForm1 from "./common/ContactForm1";
import seeting1 from '../images/seeting1.png';
import seeting2 from '../images/seeting2.png';
import seeting3 from '../images/seeting3.png';
import seeting4 from '../images/seeting4.png';
import seeting5 from '../images/seeting5.png';
import seeting6 from '../images/seeting6.png';
import '../components/css/app5.css';
import { Helmet } from "react-helmet";
const Shopify = () => {
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hovered6, setHovered6] = useState(false);


  const phoneNumber = "9045301702";

  const whatAppHandler = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const callHandler = () => {
    const callUrl = `tel:${phoneNumber}`;
    window.open(callUrl, "_blank");
  }

  return (



    <>
      <Helmet>
        <title>KushelDigi Solutions- Top shopify development company</title>
        <meta
          name="description"
          content="Kushel Digi Solutions experience, you can unlock Shopify's full potential Put your trust in the Shopify development services to build your online success."
        />
        <link rel="canonical" href="https://www.kusheldigi.com/shopify" />
      </Helmet>
      <div className="ser-main">
        <div className="shopify-bg">
          <div className="bigCommerce-back shopify-back dine-123">
            <div className="bigCommerce-back1 shopify-back1 btn-all">
              <h1 className="">
                Shopify ecommerce development <br /> services
              </h1>
              <p>
                We provide high quality Shopify Development services to provide you best Ecommerce solution for your shopping cart.
              </p>
              <a href="contact-us" className="rect-first-btn1"><button className="lets_talking"><h5>Lets get started</h5>
                <svg className="ml-2" width="15" height="13" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.0811 10.0655C14.8304 10.0655 14.6785 10.0655 14.5342 10.0655C10.1051 10.0655 5.6761 10.0655 1.24707 10.0655C0.35823 10.0655 -0.203945 9.33411 0.0695453 8.54173C0.236678 8.07696 0.578542 7.83316 1.06475 7.77982C1.20149 7.76458 1.34583 7.77982 1.48258 7.77982C5.85083 7.77982 10.2115 7.77982 14.5797 7.77982C14.7165 7.77982 14.8532 7.77982 15.0735 7.77982C14.9368 7.62744 14.8532 7.52839 14.7621 7.43696C13.2883 5.95887 11.8144 4.47316 10.333 2.99506C9.99118 2.6522 9.78606 2.27125 9.9228 1.76839C10.1431 0.976012 11.0548 0.663631 11.7081 1.15887C11.8296 1.2503 11.936 1.36458 12.0424 1.47125C14.2303 3.66554 16.4106 5.8522 18.5985 8.03887C19.1303 8.5722 19.1455 9.25792 18.6137 9.78363C16.3878 12.016 14.1543 14.2484 11.9284 16.4808C11.5865 16.8236 11.1991 17.0141 10.6977 16.8617C9.9228 16.6255 9.62652 15.7341 10.1127 15.0865C10.2039 14.9646 10.3178 14.8579 10.4242 14.7513C11.8676 13.3036 13.3035 11.8636 14.7469 10.416C14.838 10.3246 14.9292 10.2255 15.0811 10.0655Z" fill="white" />
                </svg>
              </button>
              </a>


            </div>
            <div className="bigCommerce-back2 shopify-back2">
              {/* <img className="bigcompt" src={ShopifyImg} alt="Shopify ecommerce development services" title="Shopify ecommerce development services" /> */}
              <img className="bigcompt" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724834134/ShopifyImg.3f89f08b8f9c1a923a18_jjfr87.png
" alt="Shopify ecommerce development services" title="Shopify ecommerce development services" />
            </div>
          </div>
        </div>

        <div className="affordable_shopify dine-123">
          <div className="affordable_shopify1">
            <h2>
              Affordable <span>Shopify</span> Development Solutions
            </h2>
            <p>
              We are one of the Best Shopify Development companies providing
              customized eCommerce Website Development services over the globe.
              We are having experience and a skilled in-house team of Shopify
              eCommerce Developers. As a Top Shopify Development Company, we are
              having expertise in Designing and Development of custom eCommerce
              solutions as per your business requirements. At Next Big
              Technology, we always follow the best coding standards to come up
              with a secure and responsive Shopify-based eCommerce Website.
            </p>

          </div>

          <div className="magento_service2">
            <div className="greenish1">
              {/* <img src={greenish} alt="shopify design" title="shopify design" /> */}
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724834329/greenish.3d367cb81154565e3e3b_al8jzy.png" alt="shopify design" title="shopify design" />
            </div>
            <img className="admis admis22" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724834329/hingingh.6325d70ff1e0aa7845ff_gsymnn.png" alt="Shopify development solutions" title="Shopify development solutions" />
          </div>
        </div>






        <div className="why_chose_shopify">
          <h2>Why choose our <span>Shopify</span>  development company</h2>
          <p className="php_specializied">
            Kushel Digi is a PHP-specialized software house with over 20 mid-to-senior PHP Developers. We are trusted by enterprises of all sizes to do just about anything your business needs to grow and create a reliable, secure, maintainable, and feature-ready application. Our experts solve many of the pain points you need to touch on when developing an app with PHP, from handling development lifecycle and design, to building, shipping releases as well as support and maintenance.
          </p>
          <div className="proffessional_shopify_cards">
            <div className="proffessional_shopify_card">
              <div className="proffessional_shopify_box">
                <div className="team_box">
                  <img src={laras1} alt="Professional team" title="Professional team" />
                  <div className="team_para">
                    <h4>Professional team</h4>
                    <hr />
                    <p>
                      Kushel Digi is a team with comprehensive expertise in
                      building custom software for various platforms and
                      domains. We treat every project with attention to
                      technical details and help our clients achieve their
                      business goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="proffessional_shopify_box tr7">
                <div className="team_box">
                  <img src={laras2} alt="Considerable Shopify expertise" title="Considerable Shopify expertise" />
                  <div className="team_para">
                    <h4>Considerable Shopify expertise</h4>
                    <hr />
                    <p>
                      Over the years, we’ve gained considerable expertise in
                      building custom web and mobile apps for the e-commerce
                      industry, headless development, and development with major
                      platforms like BigCommerce, Magento, and all kinds of
                      Shopify apps: public and custom.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="proffessional_shopify_card">
              <div className="proffessional_shopify_box">
                <div className="team_box">
                  <img className="teen3" src={laras3} alt="Business-focused mindset" title="Business-focused mindset" />
                  <div className="team_para">
                    <h4>Business-focused mindset</h4>
                    <hr />
                    <p>
                      Our clients are our source of gaining unique expertise,
                      new skills, and inspiration to move forward. Working on
                      new projects, we foster collaboration with customers to
                      help them improve their business strategies and achieve
                      their business goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="proffessional_shopify_box tr7">
                <div className="team_box">
                  <img src={laras4} alt="Focus on end users" title="Focus on end users" />
                  <div className="team_para">
                    <h4>Focus on end users</h4>
                    <hr />
                    <p>
                      We strive to build projects that bring value not only to
                      their creators but to end users as well. To build projects
                      with high impact, we precisely analyze the target audience
                      and their needs, conduct user research, and run various
                      hypotheses to find valuable insights.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="paperrr1">
          <div className="paper2 dine-1234">
            <h2>Our <span>Shopify</span>  App Development Services</h2>
            <p className="shop_top">
              Shopify apps connect third-party tools and processes to your
              ecommerce store, allowing you to complete a task or increase your
              workflow efficiency. They also allow you to add or customize the
              features of your store.
            </p>
            <div className="paperbox">
              <div
                className="first1"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <img
                  src={hovered ? setui : seeting1}
                  className="startup startup11"
                  alt="Custom Feature Additions" title="Custom Feature Additions"
                />
                <h3>Custom Feature Additions</h3>
                <h5>
                  Need to add a Unique feature to your store? our team can develop
                  a shopify app that gives you the custom functionality you need
                  to delight your customers and boost conversions.
                </h5>
              </div>
              <div
                className="first1"
                onMouseEnter={() => setHovered2(true)}
                onMouseLeave={() => setHovered2(false)}
              >
                <img
                  src={hovered2 ? soin2 : seeting2}
                  className="startup startup11"
                  alt="Third-party Integrations" title="Third-party Integrations"
                />
                <h3>Third-party Integrations </h3>
                <h5>
                  Connect the tools that work best for you. Integrate your ERP,
                  CRM, Shipping, accounting, payment and others tools with a
                  custom Shopify app that we build and tailor for you.
                </h5>
              </div>
              <div
                className="first1"
                onMouseEnter={() => setHovered3(true)}
                onMouseLeave={() => setHovered3(false)}
              >
                <img
                  src={hovered3 ? soin3 : seeting3}
                  className="startup startup11"
                  alt="App Installation" title="App Installation"
                />
                <h3>App Installation</h3>
                <h5>
                  Our team will help you install and configure the custom apps you
                  need, verifying changes and taking backups so your store data is
                  always safe and secure.
                </h5>
              </div>
              <div
                className="first1"
                onMouseEnter={() => setHovered4(true)}
                onMouseLeave={() => setHovered4(false)}
              >
                <img
                  src={hovered4 ? soin4 : seeting4}
                  className="startup startup12"
                  alt="App Configuration" title="App Configuration"
                />
                <h3>App Configuration</h3>
                <h5>
                  We can also help you configure and optimize any existing apps
                  you have installed, so that you can get the best performance out
                  of your ecommerce store.
                </h5>
              </div>
              <div
                className="first1"
                onMouseEnter={() => setHovered5(true)}
                onMouseLeave={() => setHovered5(false)}
              >
                <img
                  src={hovered5 ? soin5 : seeting5}
                  className="startup startup11"
                  alt="Custom Theme Apps" title="Custom Theme Apps"
                />
                <h3>Custom Theme Apps</h3>
                <h5>
                  Want to make tweaks to your shopify theme? we can build a custom
                  theme app to help you add custom features, edit your shopify
                  themes, and import or export setting
                </h5>
              </div>
              <div
                className="first1"
                onMouseEnter={() => setHovered6(true)}
                onMouseLeave={() => setHovered6(false)}
              >
                <img
                  src={hovered6 ? soin6 : seeting6}
                  className="startup startup11"
                  alt="Custom Marketing Apps" title="Custom Marketing Apps"
                />
                <h3>Custom Marketing Apps</h3>
                <h5>
                  We can also build custom Shopify apps to help you connect your
                  marketing tech stack, segment audiences, automate A/B tests, and
                  get real-time reports
                </h5>
              </div>
            </div>
          </div>
        </div>


        <div className="main-box">
          <div className="box111">
            <h2 className="heading-1">
              Key Benefits Of <snap className="blue1">Shopify </snap> Development
            </h2>
          </div>
          <div className="box-2">
            <div className="content coniuy">
              <div className="content-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2"> SIMPLE AND EASY INSTALLATION</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk1} alt="SIMPLE AND EASY INSTALLATION" title="SIMPLE AND EASY INSTALLATION" className="icon1111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Incredibly easy to use and configure, even for a non-technical
                    positions.
                  </p>
                </div>
              </div>
              <div className="content-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2"> HOSTED SERVER</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk2} alt="HOSTED SERVER" title="HOSTED SERVER" className="icon2111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Shopify provides a secure &amp; affordable Hosted server for your
                    eCommerce store, making it easy to manage business.
                  </p>
                </div>
              </div>
              <div className="content-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2">
                        {" "}
                        ATTRACTIVE &amp; MOBILE FRIENDLY UI
                      </h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk3} alt=" ATTRACTIVE MOBILE FRIENDLY UI" title="ATTRACTIVE MOBILE FRIENDLY UI" className="icon3111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Shopify offers great excerpts of antiphonal themes that are optimised for every possible blueprint.
                  </p>
                </div>
              </div>
              <div className="content-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2"> RICH-FEATURED</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk4} alt=" RICH-FEATURED" title=" RICH-FEATURED" className="icon4111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Boost your sales success and increase your company's productivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="box-3">
            <div className="content-2">
              <div className="content-2-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2"> EASY NAVIGATION</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk5} alt="EASY NAVIGATION" title="EASY NAVIGATION" className="icon5111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Provides easy navigation to keep your potential users interested.
                  </p>
                </div>
              </div>
              <div className="content-2-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2"> MULTI-STORE FACILITIES</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk6} alt="MULTI-STORE FACILITIES" title="MULTI-STORE FACILITIES" className="icon6111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Manages multiple storefronts for various brands from a single place.
                  </p>
                </div>
              </div>
              <div className="content-2-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2"> SHOPIFY APPS</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk7} alt=" SHOPIFY APPS" title=" SHOPIFY APPS" className="icon7111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Shopify Apps Store has 4,000+ certified apps making it easier to
                    customize your store.
                  </p>
                </div>
              </div>
              <div className="content-2-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2">SECURED ADMIN PANEL</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk8} alt="SECURED ADMIN PANEL" title="SECURED ADMIN PANEL" className="icon8111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Implements well-established security standards and safety factors.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="box-4">
            <div className="content-3">
              <div className="content-3-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2">VARIED PRODUCTS CATALOGS</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk9} alt="iVARIED PRODUCTS CATALOGSk" title="VARIED PRODUCTS CATALOGS" className="icon9111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Makes it easy to manage a variety of product catalogs.
                  </p>
                </div>
              </div>
              <div className="content-3-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2">THIRD-PARTY INTEGRATION</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk10} alt="THIRD-PARTY INTEGRATION" title="THIRD-PARTY INTEGRATION" className="icon10111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Expands the scope of custom integrations available to an online
                    business.
                  </p>
                </div>
              </div>
              <div className="content-3-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2">SEO-FRIENDLY</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk11} alt="SEO-FRIENDLY" title="SEO-FRIENDLY" className="icon11111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    Better SEO functionality and execution to draw more traffic.
                  </p>
                </div>
              </div>
              <div className="content-3-1">
                <div className="inner-box">
                  <div className="inner-box-1">
                    <div className="box-content-1">
                      <h3 className="heading-2">API INTEGRATION</h3>
                    </div>
                    <div className="box-content-2">
                      <img src={ikk12} alt="API INTEGRATION" title="API INTEGRATION" className="icon12111" />
                    </div>
                  </div>
                  <hr className="hr-1" />
                  <p className="pra-1">
                    API framework allows you to integrate web services and Third-party
                    CRM, ERP &amp; POS systems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="php_portfolio">
          <div className="php_port">
            <h2 className="our_php">Our <span>Shopify</span>  Web Development <span>Portfolio</span> </h2>
          </div>
        </div>


        <div className="gting">
          <Splide
            className="w-full"
            aria-label="Our Services"
            options={{
              perPage: 1,
              perMove: 1,
              autoplay: true,
              pauseOnHover: true,
              type: "loop",
              interval: 2200,
              drag: true,
            }}
          >
            <SplideSlide>
              <div className="kickta_big kickta_bigs">
                <div className="kickta_big1 kickta_bigst1">
                  {/* <img src={nuting} alt="Pain Relieving" title="Pain Relieving" /> */}
                  <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835063/Group_1000007082_lvzjip.png" alt="Pain Relieving" title="Pain Relieving" />
                  <div className="headst_big">
                    <h3>Pain Relieving <br /> better</h3>


                  </div>
                  <div className="kickma-arrow view-web">
                    {/* <p>View Website</p> */}

                    <a href="https://nufabrx.com/" target="_blank">
                      <button>
                        <span>View Website</span>
                        <svg className="ml-2" width="19" height="16" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.0811 10.0655C14.8304 10.0655 14.6785 10.0655 14.5342 10.0655C10.1051 10.0655 5.6761 10.0655 1.24707 10.0655C0.35823 10.0655 -0.203945 9.33411 0.0695453 8.54173C0.236678 8.07696 0.578542 7.83316 1.06475 7.77982C1.20149 7.76458 1.34583 7.77982 1.48258 7.77982C5.85083 7.77982 10.2115 7.77982 14.5797 7.77982C14.7165 7.77982 14.8532 7.77982 15.0735 7.77982C14.9368 7.62744 14.8532 7.52839 14.7621 7.43696C13.2883 5.95887 11.8144 4.47316 10.333 2.99506C9.99118 2.6522 9.78606 2.27125 9.9228 1.76839C10.1431 0.976012 11.0548 0.663631 11.7081 1.15887C11.8296 1.2503 11.936 1.36458 12.0424 1.47125C14.2303 3.66554 16.4106 5.8522 18.5985 8.03887C19.1303 8.5722 19.1455 9.25792 18.6137 9.78363C16.3878 12.016 14.1543 14.2484 11.9284 16.4808C11.5865 16.8236 11.1991 17.0141 10.6977 16.8617C9.9228 16.6255 9.62652 15.7341 10.1127 15.0865C10.2039 14.9646 10.3178 14.8579 10.4242 14.7513C11.8676 13.3036 13.3035 11.8636 14.7469 10.416C14.838 10.3246 14.9292 10.2255 15.0811 10.0655Z" fill="white" />
                        </svg>
                      </button>
                    </a>

                  </div>
                </div>

              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="kickta_big kickta_bigs1">
                <div className="kickta_big1 kickta_bigst2">
                  <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835143/Group_1000007099_sor84q.png" alt="summer-ready" title="summer-ready" />
                  <div className="headst_big">
                    <h3>summer-ready <br /> skin</h3>
                  </div>
                  <div className="kickma-arrow view-web">
                    <a href="https://skknbykim.com/" target="_blank">
                      <button>
                        <span>View Website</span>
                        <svg className="ml-2" width="19" height="16" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.0811 10.0655C14.8304 10.0655 14.6785 10.0655 14.5342 10.0655C10.1051 10.0655 5.6761 10.0655 1.24707 10.0655C0.35823 10.0655 -0.203945 9.33411 0.0695453 8.54173C0.236678 8.07696 0.578542 7.83316 1.06475 7.77982C1.20149 7.76458 1.34583 7.77982 1.48258 7.77982C5.85083 7.77982 10.2115 7.77982 14.5797 7.77982C14.7165 7.77982 14.8532 7.77982 15.0735 7.77982C14.9368 7.62744 14.8532 7.52839 14.7621 7.43696C13.2883 5.95887 11.8144 4.47316 10.333 2.99506C9.99118 2.6522 9.78606 2.27125 9.9228 1.76839C10.1431 0.976012 11.0548 0.663631 11.7081 1.15887C11.8296 1.2503 11.936 1.36458 12.0424 1.47125C14.2303 3.66554 16.4106 5.8522 18.5985 8.03887C19.1303 8.5722 19.1455 9.25792 18.6137 9.78363C16.3878 12.016 14.1543 14.2484 11.9284 16.4808C11.5865 16.8236 11.1991 17.0141 10.6977 16.8617C9.9228 16.6255 9.62652 15.7341 10.1127 15.0865C10.2039 14.9646 10.3178 14.8579 10.4242 14.7513C11.8676 13.3036 13.3035 11.8636 14.7469 10.416C14.838 10.3246 14.9292 10.2255 15.0811 10.0655Z" fill="white" />
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="kickta_big kickta_bigs2">
                <div className="kickta_big1 kickta_bigst3">
                  {/* <img className="trryr" src={skinniuy} alt="classic matte" title="classic matte" /> */}
                  <img className="trryr" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835145/Group_1000007102_m2awoy.png" alt="classic matte" title="classic matte" />
                  <div className="headst_big">
                    <h3>The classic matte <br /> palette</h3>

                    <p className="mt-2">Your everyday palette</p>
                  </div>
                  <div className="kickma-arrow view-web">
                    <a href="https://kyliecosmetics.com/en-in" target="_blank">
                      <button>
                        <span>View Website</span>
                        <svg className="ml-2" width="19" height="16" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.0811 10.0655C14.8304 10.0655 14.6785 10.0655 14.5342 10.0655C10.1051 10.0655 5.6761 10.0655 1.24707 10.0655C0.35823 10.0655 -0.203945 9.33411 0.0695453 8.54173C0.236678 8.07696 0.578542 7.83316 1.06475 7.77982C1.20149 7.76458 1.34583 7.77982 1.48258 7.77982C5.85083 7.77982 10.2115 7.77982 14.5797 7.77982C14.7165 7.77982 14.8532 7.77982 15.0735 7.77982C14.9368 7.62744 14.8532 7.52839 14.7621 7.43696C13.2883 5.95887 11.8144 4.47316 10.333 2.99506C9.99118 2.6522 9.78606 2.27125 9.9228 1.76839C10.1431 0.976012 11.0548 0.663631 11.7081 1.15887C11.8296 1.2503 11.936 1.36458 12.0424 1.47125C14.2303 3.66554 16.4106 5.8522 18.5985 8.03887C19.1303 8.5722 19.1455 9.25792 18.6137 9.78363C16.3878 12.016 14.1543 14.2484 11.9284 16.4808C11.5865 16.8236 11.1991 17.0141 10.6977 16.8617C9.9228 16.6255 9.62652 15.7341 10.1127 15.0865C10.2039 14.9646 10.3178 14.8579 10.4242 14.7513C11.8676 13.3036 13.3035 11.8636 14.7469 10.416C14.838 10.3246 14.9292 10.2255 15.0811 10.0655Z" fill="white" />
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="kickta_big kickta_bigs3">
                <div className="kickta_big1 kickta_bigst4">
                  {/* <img className="trryr" src={skyo} alt="Discover your" title="Discover your" /> */}
                  <img className="trryr" src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835141/Group_1000007104_eqjrwi.png" alt="Discover your" title="Discover your" />
                  <div className="headst_big">
                    <h3>Discover your <br /> better</h3>
                    <p className="mt-2">With a daily routine customized to your wellness goals</p>
                  </div>
                  <div className="kickma-arrow view-web">
                    <a href="https://www.bulletproof.com/" target="_blank">
                      <button>
                        <span>View Website</span>
                        <svg className="ml-2" width="19" height="16" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.0811 10.0655C14.8304 10.0655 14.6785 10.0655 14.5342 10.0655C10.1051 10.0655 5.6761 10.0655 1.24707 10.0655C0.35823 10.0655 -0.203945 9.33411 0.0695453 8.54173C0.236678 8.07696 0.578542 7.83316 1.06475 7.77982C1.20149 7.76458 1.34583 7.77982 1.48258 7.77982C5.85083 7.77982 10.2115 7.77982 14.5797 7.77982C14.7165 7.77982 14.8532 7.77982 15.0735 7.77982C14.9368 7.62744 14.8532 7.52839 14.7621 7.43696C13.2883 5.95887 11.8144 4.47316 10.333 2.99506C9.99118 2.6522 9.78606 2.27125 9.9228 1.76839C10.1431 0.976012 11.0548 0.663631 11.7081 1.15887C11.8296 1.2503 11.936 1.36458 12.0424 1.47125C14.2303 3.66554 16.4106 5.8522 18.5985 8.03887C19.1303 8.5722 19.1455 9.25792 18.6137 9.78363C16.3878 12.016 14.1543 14.2484 11.9284 16.4808C11.5865 16.8236 11.1991 17.0141 10.6977 16.8617C9.9228 16.6255 9.62652 15.7341 10.1127 15.0865C10.2039 14.9646 10.3178 14.8579 10.4242 14.7513C11.8676 13.3036 13.3035 11.8636 14.7469 10.416C14.838 10.3246 14.9292 10.2255 15.0811 10.0655Z" fill="white" />
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </SplideSlide>

          </Splide>
        </div>


        <div className="shopify_benifits">
          <h3>Benefits of Custom Shopify Application Development</h3>
          <div className="shopify_benifits_card">
            <div className="shopify_benifits_box">
              <img src={toning1} alt="Feature-Rich App" title="Feature-Rich App" />
              <h4>Feature-Rich App</h4>
            </div>
            <div className="shopify_benifits_box">
              <img src={toning2} alt="Workflow Management" title="Workflow Management" />
              <h4>Workflow Management</h4>
            </div>
            <div className="shopify_benifits_box">
              <img className="toning3" src={toning3} alt="Higher Conversation" title="Higher Conversation" />
              <h4>
                Higher Conversation <br /> Rates
              </h4>
            </div>
            <div className="shopify_benifits_box">
              <img className="toning4" src={toning4} alt=" Security And realibiliy" title="Security And realibiliy" />
              <h4>
                Security And <br /> Reliability
              </h4>
            </div>
          </div>
          <a href="contact-us"><button>Develop A Shopify App</button></a>
        </div>

        <div className="shopify-work">
          <h3>How It Works?</h3>
          <div className="shpoify_work_card">
            <div className="shpoify_work_box">
              <div className="golt">
                <div className="goal_one">
                  <p>1</p>
                </div>
                <h3>Discovery</h3>
              </div>
              {/* <img src={laps} alt="kusheidigi works" title="kusheidigi works" /> */}
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835409/laps.1642fd42967f6ad948f4_ph08pr.png" alt="kusheidigi works" title="kusheidigi works" />
            </div>
            <div className="shpoify_work_box">
              <div className="golt">
                <div className="goal_one">
                  <p>2</p>
                </div>
                <h3>Design</h3>
              </div>
              {/* <img src={laptop2} alt="kusheidigi project" title="kusheidigi project" /> */}
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835408/laptop2.5b4663eeb54fc5a371ee_uzhh9i.png" alt="kusheidigi project" title="kusheidigi project" />
            </div>
            <div className="shpoify_work_box">
              <div className="golt">
                <div className="goal_one">
                  <p>3</p>
                </div>
                <h3>Development</h3>
              </div>
              {/* <img src={laptop3} alt="kusheidigi works" title="kusheidigi works" /> */}
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835408/laptop3.3ce5a7296e02d5a28984_sesofs.png" alt="kusheidigi works" title="kusheidigi works" />
            </div>
            <div className="shpoify_work_box">
              <div className="golt">
                <div className="goal_one">
                  <p>4</p>
                </div>
                <h3>Testing</h3>
              </div>
              {/* <img src={laptop4} alt="kusheidigi works" title="kusheidigi works" /> */}
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835407/laptop4.a3fad49360e2b49c39c4_j8accs.png" alt="kusheidigi works" title="kusheidigi works" />
            </div>
            <div className="shpoify_work_box">
              <div className="golt">
                <div className="goal_one">
                  <p>5</p>
                </div>
                <h3>Delivery</h3>
              </div>
              {/* <img src={laptop5} alt="kusheidigi project" title="kusheidigi project" /> */}
              <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1724835410/laptop5.b38d9300272b88dd68de_qzygmi.png" alt="kusheidigi project" title="kusheidigi project" />
            </div>
          </div>
        </div>


        <ContactForm1 />
        <div className="home9 home967  home278">
          <div className="home91 px-24 dine-1234">
            <div className="home911 text-center">
              <h2 className="font-bold text-gray-700">Frequently Asked<br /> Questions</h2>
            </div>
            <div className="home912 FAQ-1">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Why choose Shopify for e-commerce development?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Shopify is user-friendly, feature-rich, and offers a seamless e-commerce experience.                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Give some examples  of Shopify websites developed in the past?

                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Absolutely! Our portfolio includes successful Shopify websites tailored to various businesses.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you provide custom Shopify theme development services?

                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we offer custom theme development to match your brand and business requirements.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can you assist with app integration into Shopify stores?

                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we have experience integrating apps to enhance the functionality of Shopify stores.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you give Post-launch support and maintenance for Shopify websites?

                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we provide ongoing support to ensure your Shopify website remains secure and operational.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="whtsApBtns">
          <button onClick={whatAppHandler}>
            <img className="what-image-universal" src={whatsApp} alt="whatsApp-kusheldigi" title="whatsApp-kusheldigi" />
          </button>
          <button onClick={callHandler}>
            <img src={call} alt="call-icon" title="call-icon" />
          </button>

        </div>

      </div>
    </>
  );
};

export default Shopify;
