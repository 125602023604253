import React from 'react'

import arrow4 from "../images/arrow4.png";
import { NavLink } from "react-router-dom";
import trackent1 from "../images/trackent1.png";
import solenoidfff from "../images/solenoidfff.png";
import soling1 from '../images/soling1.jpg';
import soling2 from '../images/soling2.jpg';
import soling3 from '../images/soling3.jpg';
import img4 from "../images/Group 1000004718.png";
import img5 from "../images/Group 1000004715.png";
import img6 from "../images/Group 1000004716.png";
import img13 from "../images/Group 1000006500 (1).png";
import hh10 from "../images/hh10.png";
import hh12 from "../images/hh12.png";
import hh13 from "../images/hh13.png";
import hh14 from "../images/hh14.png";

import hh9 from "../images/hh9.png";
import pru3 from '../images/pru3.png';
import opqweeeeeee from "../images/opqweeeeeee.png";
import opqweeeeeee2 from "../images/opqweeeeeee2.png";
import opqweeeeeee3 from "../images/opqweeeeeee3.png";
import madlogooooo from "../images/madlogooooo.png";
import mad5 from "../images/mad5.png";

import whatsApp from "../images/whatsapp.png";
import call from "../images/telephone.png"

const FairChildFun = () => {


  const phoneNumber = "9045301702";

  const whatAppHandler = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const callHandler=()=>{
    const callUrl = `tel:${phoneNumber}`;
    window.open(callUrl, "_blank");
  }


  return (
    <>
    <helmet>
    <link rel="canonical" href="https://www.kusheldigi.com/solenoid-ninja"/>
    </helmet>
          <div className="ser-main">
        
        <div>
      <div className="side-bg-trio">
        <div className="first-kick-pads">
          <div className="left-section-kids">
            <img src={pru3} className='pru3'  alt="img"></img>
            <p className="shoots_premium">
             
            FairChild Fun is an organization that holds multiple classes, events, and activities for kids, including art, craft, and auto hobby sessions. 
            {/* The client wanted an innovative e-commerce platform capable of handling class registrations, events, and website builders with features for content management in order to keep the platform vibrant and up-to-date. */}
            </p>
            <h3>Technologies Used</h3>
            <div className="main-box-logic flex">
              <img src={opqweeeeeee} id="sim" alt="img"></img>
              <img src={opqweeeeeee2} id="rotat" alt="img"></img>
            </div>
          </div>
          <div className="right-section-kids">
            <img src={solenoidfff} id="total-group" alt="img"></img>
          </div>
        </div>
        {/* ........................................ */}
        <div className="MadFish_press">
              <div className="MadFish-back">
                <div className="MadFish-back1">
                  <div className="MadFish_press_head">
                    <img src={madlogooooo} alt="madlogooooo" />
                  </div>
                  <div className="MadFish_press_para">
                    <p>
                    FairChild Fun is an organization that holds multiple classes, events, and activities for kids, including art, craft, and auto hobby sessions. The client wanted an innovative e-commerce platform capable of handling class registrations, events, and website builders with features for content management in order to keep the platform vibrant and up-to-date.



                    </p>
                  </div>
                </div>
                <div className="MadFish-back2">
                  <img src={mad5} alt="MadFish" />
                </div>
              </div>
            </div>
        {/* ************************************************** */}
        <div className="second-service-conte">
          <p className="service-hinking">Services Provided</p>
          <div className="main-img-mighty">
            <div className="bepolo">
              <img src={img4} id="ux-safari" alt="img"></img>
              <p>UX/UI Website Design</p>
            </div>
            <div className="bepolo">
              <img src={img5} id="web-safari" alt="img"></img>
              <p>Website Development</p>
            </div>
            <div className="bepolo">
              <img src={img6} id="qa-safari" alt="img"></img>
              <p>QA & Website Testing</p>
            </div>
          </div>
        </div>
      </div>
      </div>
 {/* *********************************************** */}
 <div className="third-wireframe-homie-solenoid-s">
        <div className="inner-third-local-solenoid-s">
          <p>Wireframes</p>
          <div className="three-section-lab-solenoid-s">
           
            <div className="single_big-solenoid-s">
            <img src={hh9} id="pads-cats" alt="img"></img>
            </div>
           
          </div>
        </div>
      </div>
          {/* ******************************************** */}

          <div className="fourth-plate-juuls">
        <div className="left-card-hub">
          <div className="vl-chic">
            <p className="fila">Font</p>
            <div className="ywo-stem">
              <div className="light-aa">
                <p className="hoo">Aa</p>
                <p className="roboto-tip">Gilroy</p>
              </div>
              <div className="dark-aa">
                <p className="dark-canny">Aa</p>
                <p className="prompt-tip">Montserrat</p>
              </div>
            
            </div>
            <div className="sub-worm">
              <p className="tool">Tools Use</p>
              <img src={img13} id="pals-circle" alt="img"></img>
            </div>
          </div>
        </div>
        <div className="right-card-omega">
          <p>Color Plate</p>
          <img src={hh10} id="gang" alt="img" />
        </div>
      </div>{" "}



        <div className="back-story">
          <div className="step1-back">
            <div className="step-backs1">
              <h3 className="nila">01</h3>
              <h3>BACKSTORY</h3>
            </div>
            <div className="step-backs2">
              <p>
              FairChild Fun approached Kushel Digi Solutions to develop a scalable e-commerce website that would serve the business purpose of managing events and classes with ease, along with users creating personal pages showcasing their children's projects. A full-featured Content Management System was also required, whereby updating the content for the new events/ classes introduced should be easy.

              </p>
          
            </div>
          </div>
          <div className="step-images flex items-center justify-center mt-20">
            <img className="stepback solenoid-stepback" src={hh13} alt="stepback" />
            <img className="stepback1 solenoid-stepback1" src={hh12} alt="stepback1" />
          </div>
        </div>




        <div className="challenge-story">
          <div className="challenge-story1">
            <h3 className="nila">02</h3>
            <h3>
              Challenges  & Solutions
            </h3>
          </div>
          <div className="challenge-story2 flex">
            <div className="challenge-box">
              <h4>Challenges</h4>
              <div className="chal"></div>
              <div className="challenge-list mt-8">

                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p> Developing user-friendly website builder for non-technical users. </p>
                </div>

              
                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p> Integrating a CMS to update the content dynamically on the website along with technologies like big commerce, laravel & next js.
                  </p>
                </div>
              
                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p>To manage different classes, events, and their registrations effectively.
                  </p>
                </div>
              
                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p>The platform needed to be scalable yet user-friendly.                  </p>
                </div>
              
              </div>
            </div>
            <div className="challenge-box ml-3">
              <h4>Solutions</h4>
              <div className="chal"></div>
              <div className="challenge-list mt-8">
                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p>
                  Integrated the combination of Laravel as the backend, BigCommerce as e-commerce, Next.js as the seamless frontend, and integrated CMS for easy updating of content.

                  </p>
                </div>

                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p>
               Easy-to-use drag-and-drop website builder developed to let users create and manage their personal pages with ease.


                  </p>
                </div>
                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p>
                  A dynamic system of event scheduling, management, and class registrations.


                  </p>
                </div>
                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p>
                  Ensuring smooth performance and accessibility across all devices.



                  </p>
                </div>
                <div className="challenge-list1">
                  <div className="nilust"></div>
                  <p>
                  Online expertise

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kick-start kick-start22">
          <div className="kick-first kick-first222">
        
            <div className="kickds">
                <img className="kick_mart" src={trackent1} alt="kick5" />
            </div>
          </div>
          <div className="kick-second kick-second222 relative">
          
            <img className=" relative z-10 about162 pick222" src={hh14} alt="pick" />
          </div>
        </div>

        <div className="azib">
          <div className="azib1">
            <div className="azib1-sect">
              <div className="azib1-first">
                <h3 className="azib-pa">04</h3>
                <h3>RESULT</h3>
              </div>
              <div className="azib1-second">
                <p>
                The integration of the website builder and CMS drastically enhanced user engagement, and parents were actively creating and managing customized pages. Fair Child Fun Events witnessed a 35% increase in event sign-ups due to the effective event management system. The platform proved scalable, and the CMS allowed easy updating of content without performance degradation. Overall, the project enhanced the overall user experience and operational efficiency, thus gaining positive feedback from the users.

                </p>
                
                <div className="azib-tech">
                    <h3>Technologies Used</h3>
                    <img className="conjun" src={opqweeeeeee3} alt="conjun" />
                </div>
              </div>
            </div>

              <div className="azib-images flex items-center justify-center">
                  <img className='soling1' src={soling1} alt="soli" />
                  <img className='soling1' src={soling2} alt="soli" />
                  <img className='soling1' src={soling3} alt="soli" />
              </div>
          </div>
        </div>

        <div className="client-apperication">
          <h2>Client Appreciation</h2>
          <p>
            “Thank you Affle team for all your hardwork, patience and continuous
            support. The app is getting positive reviews and we hope to continue
            to improve and evolve the app. Looking forward to our continued
            growth.”
          </p>
          <h4>Sandeep Sharma</h4>
        </div>

        <div className="req">
          <div className="req-sect">
            <h2>
              Would You Like To Talk To <br /> About Your Project?
            </h2>
            <p>
              {" "}
              You can send us the details of your project, and a member of our
              team will contact you shortly.
            </p>
          <NavLink to="/contact-us"><button>
              Request A Call{" "}
              <img width={13} className=" inline ml-2" src={arrow4} alt="ml" />
            </button></NavLink>
          </div>
        </div>

               {/*  buttons  */}
               <div className="whtsApBtns">
          <button onClick={whatAppHandler}>
            <img className="what-image-universal" src={whatsApp} alt="whatsApp-kusheldigi" title="whatsApp-kusheldigi" />
          </button>
          <button onClick={callHandler}>
            <img src={call} alt="call-icon" title="call-icon" />
          </button>

        </div>
      </div>
    </>
  )
}

export default FairChildFun;