import "./header.css"
import bgcircle2 from "../../images/bgcircle2.png"

function BigSection2() {
  return (
    <div className='bigsec22wrap'>

        <div className="gisec2conta">

            {/* left side */}
            <div className="gisec2clefts">
                <p>BigCommerce services</p>
                <h2>Build, optimize & grow your BigCommerce store.</h2>
                <p className="gise2plepara2">We design, develop and optimize BigCommerce websites to attract, engage and convert loyal repeat customers. Whether you are looking to migrate to BigCommerce, custom design and develop a BigCommerce store, integrate your backend systems or build a custom BigCommerce app or headless solution, we stand committed as your dedicated growth partner throughout your entire project and after.</p>
            </div>

  <div className="rigmimage">
 <img src={bgcircle2} className="bgcircle2BIG" alt="" />
            <img src="https://res.cloudinary.com/dd9tagtiw/image/upload/v1725534481/Group_1171275829_sod336.png" className="bigcom2img" alt="" />
  </div>

        </div>

    </div>
  )
}

export default BigSection2