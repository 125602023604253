import "./bigs7.css"
function YourProject() {
  return (
    <div className="yprwrap">

   <div className="yrproconta">

    <div className="yrcontentns">
        <h3>Start Your Project. It’s Risk-Free.</h3>
        <p>Let's make it happen! With our risk-free approach, you can rest assured that your project is in good hands. Submit your inquiry today and receive a prompt response from our team.</p>

        <button><span>Get A Quote For Your Project</span></button>

        <span className="undeliconv">Or Start a conversation</span>
    </div>

   </div>


    </div>
  )
}

export default YourProject