import { useState } from "react"
import "./big8.css"


const data = [
[] , 
[
        {
            num:"01" , 
            heading:"Requirement Gathering" , 
            para:"Collaborate with stakeholders to understand business needs, customer requirements, and project goals to ensure a clear and comprehensive set of specifications, all while working with our leading BigCommerce development services team."
        } , 
        {
            num:"02" , 
            heading:"Design UI/UX" , 
            para:"Develop intuitive and aesthetically pleasing user interfaces and experiences, focusing on enhancing user engagement and satisfaction through thoughtful design principles."
        } , 
        {
            num:"03" , 
            heading:"Development" , 
            para:"Implement robust and scalable solutions using the latest technologies, ensuring seamless functionality and integration with existing systems, all managed by our experienced BigCommerce development company."
        } , 
        {
            num:"04" , 
            heading:"Testing and Deployment" , 
            para:"Conduct thorough testing to identify and resolve defects, ensuring the final product meets quality standards and performs optimally across various scenarios, all overseen by our dedicated BigCommerce development company."
        } , 
        {
            num:"05" , 
            heading:"Maintenance & Support" , 
            para:"Conduct thorough testing to identify and resolve defects, ensuring the final product meets quality standards and performs optimally across various scenarios, all overseen by our dedicated BigCommerce development company."
        } , 
    ]
]

const data2 = [1 , 2 , 3 , 4 , 5 ]

function BigSection8() {

    const [currindex , setcurrIndex] = useState(1);

  return (
    <div className="bigs8wrap">

        <div className="big8conta">

            <div className="bgs8content">
                <h3>Our BigCommerce Development Services Process</h3>
                <p>We care about transparency. Here are the steps of BigCommerce development conversion to clarify this process for you.</p>
            </div>

            <div className="numfilters">

             {
                data2.map((item , index)=>(
                    <div key={index} className={`singnum  ${index != 4 && 'addwidths' } `}>
                        <span className={`${currindex === item && "cursinnum"}`}>{item}</span>
                        {
                            index !== 4 && 
                        <p className="nufilline"></p>
                        }
                    </div>
                ))
             }

            </div>

            <div className="bs8items">

                {
             data[currindex]?.map((item , i)=>(
                 <div key={i} className="bigs8single">
                   <span>{item.num}</span>
                   <h5>{item.heading}</h5>
                   <p>{item.para}</p>
                 </div>
             ))
                }

            </div>


            <button className="discuproject"><span>Let’s Discuss your Project</span></button>

        </div>

    </div>
  )
}

export default BigSection8