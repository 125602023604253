import React, { useState } from "react";
import whatsApp from "../images/whatap.png"
import ContactForm1 from "./common/ContactForm1";

const Contact = ({ notify }) => {
  const pattern = new RegExp(/^\d{1,9}$/);
  const [isError, setIsError] = useState(false);
  const [flag,setFlag] = useState(false);
  const [tab, setTab] = useState(1);

  const tabAnother = (e) => {
    e.preventDefault();
    setTab(1);
    document.getElementById("abc").style.color = "#085881";
    document.getElementById("def").style.color = "#0E2B3D";
    document.getElementById("abc").style.borderBottom = "3px solid #085881";
    document.getElementById("def").style.borderBottom = "none";
  };
  const tabAnother1 = (e) => {
    e.preventDefault();
    setTab(2);
    document.getElementById("abc").style.color = "#0E2B3D";
    document.getElementById("def").style.color = "#085881";
    document.getElementById("abc").style.borderBottom = "none";
    document.getElementById("def").style.borderBottom = "3px solid #085881";
  };

  const [val, setVal] = useState({
    company1: "",
    name1: "",
    email1: "",
    service1: "",
    message1: "",
  });

  const [val1,setVal1] = useState({
    phone1: "",
  })


  const handleChange = (e) => {
    setVal({ ...val, [e.target.name]: e.target.value });
  };

  const handleChange1 = (e) =>{
    setVal1({...val1, [e.target.name]: e.target.value});
    if (!pattern.test(e.target.value))
      setIsError(true);
    else setIsError(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // const res = await fetch("https://kushel-digi.onrender.com/contact1", {
    const res = await fetch("https://backend.kusheldigi.com/contact1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({...val,...val1}),
    });
    const data = await res.json();
    console.log(data)
    setFlag(true)
    // alert(data.message);
    notify("success", data.message);

    setTimeout(() => {
      
      window.location.href="/contact-us"
    }, 1000);
    
    setVal({
      company1: "",
      name1: "",
      email1: "",
      phone1: "",
      service1: "",
      message1: "",
    });
    setVal1({
      phone1:""
    })
   
  };


  const headings =
    "Our touch: crafting mobile-ready eCommerce for a seamless experience on any device.";

  const headings1 =
    "Stay up, even when traffic skyrockets, thanks to robust server management.";

  const headings2 =
    "We design with the latest UX and UI trends, ensuring your site shines!";

  let headings3 =
    "Testing in a staging environment guarantees smooth app deployment – no hiccups.";

  const phoneNumber = "+91-9045-301-702"

  const whatAppHandler = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  }


  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

 const font2 = () =>{
  if(val1.phone1.length> 9){
    document.getElementById("funts").disabled = true
  }
  else{
    document.getElementById("funts").disabled = false
  }

  document.getElementById("funts").disabled = false

 }
  return (
    <>
    <helmet>
    <link rel="canonical" href="https://www.kusheldigi.com/contact-us"/>
    <title>Contact us | Kushel Digi Solutions | Ecommerce Web Development Company</title>
        <meta
          name="description"
          content="Contact us - Kushel Digi Solutions We offer Mobile App Development  of web Development. Reach Us +91-9045301702 or email- info@kusheldigi.com."
        />
    </helmet>
      <div className="ser-main">
        <div className="contact-back">
          <div className="contact-sect">
            <h2>Let's Connect</h2>
            <p>We're here to assist you, and answer your questions.</p>
            <p>Feel free to get in touch with us anytime.</p>
          </div>
        </div>

       

      <ContactForm1 notify={notify} />





        {/*  buttons  */}
        <div className="whtsApBtns">

          <button onClick={whatAppHandler}><img src={whatsApp} alt="whatsApp" title="whatsApp" /></button>
         
        </div>


      </div>
    </>
  );
};

export default Contact;




