import React from 'react'
import BigHeader from './BigHeader'
import BigSection2 from './BigSection2'
import "./header.css"
import BigSection3 from './BigSection3'
import Bigsection6 from './Bigsection6'
import BigSection7 from './BigSection7'
import BigSection9 from './BigSection9'
import Home2Section10 from '../Home/Home2Section10'
import Home2Testimonial from '../Home/Home2Testimonial'
import YourProject from './YourProject'
import Home2AskQuestion from '../Home/Home2AskQuestion'
import ContactForm1 from '../common/ContactForm1'
import Home2BrandSwiper from '../Home/Home2BrandSwiper'
import BigSection8 from './BigSection8'
import BigSection5 from './BigSection5'

function BigCommerce3({notify}) {
  return (
    <div className="home-main">

        <BigHeader />

        <BigSection2 />

        <p className="linesbig"></p>

        <BigSection3 />

        <Home2BrandSwiper title={"Featured Technology Partners"} />

        <BigSection5 />

<Bigsection6 />

<BigSection7 />

<BigSection8 />

{/* <BigSection9  /> */}

<Home2Section10  heading={"Industries we can help develop outstanding"} para={"We're masters at crafting bespoke online stores that captivate, engage, and supercharge sales. With our tech-savvy sorcery, we empower businesses to conquer the digital realm, summoning customers, and skyrocketing revenue."}/>

<Home2Testimonial  />

<YourProject />

<Home2AskQuestion />

<ContactForm1 notify={notify} />



    </div>
  )
}

export default BigCommerce3